@border_color: #b9b9b9;
@border_color_light: #D3D3D3;
@font_color: #333;
@font_color_light: #aaa;
@link_color: rgb(66,117,198);

.comment {
    padding: 2px;
    min-width:275px;
    font-size: 16px;
    line-height: 20px;

    &, * {
        /* Bootstrap v3 set box-sizing to border-box */
        box-sizing: content-box;
    }

    li + li {
        margin-top: 5px;
    }

    a {
        &:link, &:visited {
            color: @link_color;
        }
    }
    ol.commentOl, ol.reply {
        margin-bottom: 10px;
        margin-left: 25px;
        margin-right: 0px;
        margin-top: 0px;
        padding: 0px;
        /* we don't want any bullet or number */
        list-style: none outside none;
    }

    ol.commentOl {
        margin-left: 0px;
        margin-bottom: 0px;
    }

    input, textarea, button {
        &:focus {
            outline: none; /*fix orange border in chrome*/
            box-shadow: none;
        }
    }

    input,
    textarea {
        color: #333333; /* reset for bootstrap */
        font-size: 14px;
        line-height: 20px;
    }

    input {
        border: 1px solid @border_color_light;
        padding: 0px;
    }

    .threadTitle {
        color: @font_color;
        font-weight: bold;
        font-size: 85%; /*14px;*/

        &:hover {
            text-decoration: none; /*remove underline*/
        }

        &.callback:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .commentOl {
        margin-left: 0px;
        margin-bottom: 0px;

        .user-suggestion {
            height: 0px;

            input {
                height: 0px;
                visibility: hidden;
            }
            .typeahead.dropdown-menu {
                margin-top: -2px;

                li {
                    overflow-x: hidden;
                    overflow-wrap: break-word;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                a {
                    font-size: 14px !important;
                    color: #333333 !important;
                }
            }
        }

        .commentRow {
            .commententryInput:before {
                left: auto !important;
            }

            .form-control {
                height: auto;
                width: auto;
            }

            & > div {
                padding: 2px 0px;
            }

            img.avatar {
                margin-bottom: 4px;
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 4px;
            }

            div.username {
                font-weight: bold;
                font-size: 75%;
                padding: 0px;
                white-space: nowrap; /* needed by text-overflow:ellipsis */
                overflow: hidden; /* needed by text-overflow:ellipsis */
                text-overflow: ellipsis;
            }

            div.message,
            div.commentForm {
                word-wrap: break-word;
            }

            div.messageSys {
                text-shadow: none;
                background-color: #ffffff;
                padding: 3px;
                margin-top: 1px;
                font-size: 14px;
                line-height: 20px;
            }

            div.message {
                text-shadow: none;
                background-color: #ffffff;
                margin-top: 1px;
                font-size: 14px;
                line-height: 20px;

                & > span.editable {
                    padding: 0px;
                }

                & > textarea {
                    /* textarea for inline edit*/
                    margin: 0px;
                    padding: 0px;
                    border: none;
                    resize: none; /*remove the resize handler*/
                    min-height: 0px;
                    vertical-align: top;
                    box-shadow: none;
                }

                .inline-buttons {
                    height: 22px;
                   //position:relative;

                    button.save,
                    button.cancel {
                        background: none !important;
                        border: none;
                        padding: 0px;
                        border-spacing: 0px;
                        font-size: 12px;
                        cursor: pointer;
                        color: @link_color;
                        //line-height: 22px;
                        position: relative;
                        bottom: -5px;
                    }
                }
            }

            div.footer {
				padding:0px;
				text-align: left;
                span.pubDate {
                    color: @font_color_light;
                    font-size: 80%;
                }

                .editEntry,
                .deleteEntry {
                    color: @link_color;
                    font-size: 12px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            // ol
            .reply {
                .showAllComments {
                    line-height: 14px;

                    .showAllLink {
                        line-height: 14px;
                        font-size: 75%;
                        cursor: pointer;
                        color: @link_color;
                    }
                }

                /* vertical line for reply */
                margin-top: 5px;
                margin-left: 35px;
                padding-left: 7px;
                border-left: 1px solid @border_color;
            }

            &.commentInputRow {
                margin-left: 0px; /* remove auto in IE8 */

                .commentForm {
                    background-color: #ffffff;
                    left: -1px;
                }
            }
        }
    }

    & + .comment {
        /* add line between threads */
        border-top: 1px solid @border_color;
        padding-top: 7px;
        margin-top: 7px;
    }

    .linked-user {
        color: @link_color;
    }
}

.comments, .comment {
    .noComments {
        font-style: italic;
        color: grey;
        font-size: 14px;
    }
}
